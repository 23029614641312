import { defineStore } from 'pinia'

export const useCompanyStats = defineStore('companyStats', {
    state: () => ({
        stats: {
            volume: {
                value: '$ 25,165,000',
                descriptor: 'sold in 2023',
            },
            experience: {
                value: '21 years',
                descriptor: 'in business',
            },
            dealCount: {
                value: '430',
                descriptor: 'closed deals',
            },
            clientCount: {
                value: '175',
                descriptor: 'clients',
            },
        },
    }),
})
